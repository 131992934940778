<template>
  <div>
    <div v-if="table_targets">
      <table :class="`m1 table--perweek table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <th class="table__head--text">Ondergrens</th>
          <th class="table__head--text">Bovengrens</th>
          <th class="table__head--text">Actueel</th>
        </thead>
        <tbody>
          <td class="table__cell--default text-center">{{table_targets.ondergrens}} 
            <span v-if="table_data_sum.aantal - table_targets.ondergrens >= 0" class="green m2">
              +{{ table_data_sum.aantal -table_targets.ondergrens }}
            </span>
            <span v-else class="red m2">
              {{ table_data_sum.aantal -table_targets.ondergrens }}
            </span>
          </td>
          <td class="table__cell--default text-center">{{table_targets.bovengrens}}
            <span v-if="table_data_sum.aantal - table_targets.bovengrens >= 0" class="green m2">
              +{{ table_data_sum.aantal -table_targets.bovengrens }}
            </span>
            <span v-else class="red m2">
              {{ table_data_sum.aantal -table_targets.bovengrens }}
            </span>
          </td>
          <td class="table__cell--default text-center bg-red" v-if="table_data_sum.aantal <= table_targets.ondergrens">{{table_data_sum.aantal}}</td>
          <td class="table__cell--default text-center bg-orange" v-else-if="table_data_sum.aantal <= table_targets.bovengrens">{{table_data_sum.aantal}}</td>
          <td class="table__cell--default text-center bg-green" v-else>{{table_data_sum.aantal}}</td>
        </tbody>
      </table>
    </div>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <th class="table__head--text">Nummer</th>
        <th v-if="franchise" class="table__head--text">bu</th>
        <th class="table__head--text">Naam</th>
        <th class="table__head--text table__data--right">Aantal</th>
        <th class="table__head--text table__data--right">Target</th>
        <th class="table__head--text table__data--right">Inkopen</th>
        <th class="table__head--text table__data--right">Kosten</th>
        <th class="table__head--text table__data--right">Verkopen</th>
        <th class="table__head--text table__data--right">Commisie</th>
        <th class="table__head--text table__data--right">Stadagen</th>
        <th class="table__head--text table__data--right">Winst</th>
        <th class="table__head--text table__data--right">Target</th>
        <th class="table__head--text table__data--right">Gem. WA</th>
        <th class="table__head--text table__data--right">Gem. Winst</th>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in table_data"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">{{ item.nummer }}</td>
          <td v-if="franchise" class="table__cell--default">{{ item.bu }}</td>
          <td class="table__cell--default">
            <router-link
              :to="{
                name: 'klantenperverkoper',
                params: { bu: table_style, name: item.naam, year: year_number },
              }"
              >{{ item.naam }}</router-link
            >
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.aantal }}
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.target }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.inkopen }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.kosten }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.verkopen }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.commisie }}
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.stadagen }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.winst }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.targetamount }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.gemWA }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.gemwinst }}
          </td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding"></th>
        <th v-if="franchise" class="table__foot--padding"></th>
        <th class="table__foot--padding table__data--right">
          {{ table_data_sum.aantal }}
        </th>
        <th class="table__foot--padding table__data--right"></th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.inkopen }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.kosten }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.verkopen }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.commisie }}
        </th>
        <th class="table__foot--padding table__data--right">
          {{ table_data_sum.stadagen }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.winst }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.target }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.gemwa }}
        </th>
        <th class="table__foot--padding table__data--right">
          € {{ table_data_sum.gemwinst }}
        </th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    table_data: [Object, Array],
    table_data_sum: [Object, Array],
    table_style: [Number, String],
    year_number: [Number, String],
    table_targets: [Object, Array],
    franchise: [String, Boolean, Number]
  },
};
</script>
